<template>
  <div>
    <div v-if="isLoading"><Loader class="mt-20" :content="true" /></div>
    <TableWrapper v-else-if="!isLoading && attendanceList.length">
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, skipForFilterColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
          <TH
            v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])"
            v-permission="tableActionsPermissions"
          >
            Actions
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(attendance, idx) in attendanceList" :key="idx">
          <TD v-show="showColumnInDataTable(filtersData, 'Student Name', ['Student Name'])">
            <span>
              <SingleUserDisplay :user="attendance.student" label="full_name" :image="true" />
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Status', ['Status'])">
            <UiStatus :title="attendance.status" :success="attendance.status === PRESENT" />
          </TD>

          <TD
            v-show="showColumnInDataTable(filtersData, 'Period', ['Period'])"
            @click="redirectToDetailPage(attendance.period)"
          >
            <span class="text-primary-purple-600 cursor-pointer">
              {{ getScheduleLabel(attendance.period) }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Comment')">
            {{ attendance.comment || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Actions', ['Actions'])"
            v-permission="tableActionsPermissions"
          >
            <TableAction
              :action-list="actionList"
              :current-user="attendance"
              :idx="idx"
              @action="editAttendance"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <div v-else>
      <NoRecordFound />
    </div>
    <ValidationObserver @submit="updateAttendance">
      <UiModalContainer
        footer-classes="flex justify-end"
        :modal-show="showEditAttendanceModal"
        :modal-width="40"
        @handleClick="toogleAttendanceModal"
      >
        <template v-slot:header>Edit Attendance for {{ activeObj.student.full_name }}</template>
        <template v-slot>
          <div>
            <InputFieldWrapper>
              <UiSingleSelect
                v-model="activeObj.status"
                title="STATUS"
                rules="required"
                :label="'title'"
                :options="attendanceStatusList"
                class="text-text-color font-roboto text-sm font-normal flex-1"
              />
            </InputFieldWrapper>
          </div>
        </template>
        <template v-slot:footer>
          <div class="flex gap-3 rtl:gap-x-4">
            <UIButton @click="toogleAttendanceModal">Cancel</UIButton>
            <UIButton button="primary">
              <span>
                <span>Update</span>
              </span>
            </UIButton>
          </div>
        </template>
      </UiModalContainer>
    </ValidationObserver>
    <div class="-mr-5 md:mr-0">
      <slot name="pagination"></slot>
    </div>
  </div>
</template>

<script>
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import TableAction from '@components/TableAction.vue'
import UIButton from '@src/components/UiElements/UIButton.vue'
import UiSingleSelect from '@components/UiElements/UiSingleSelect.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { formatDate } from '@utils/moment.util'
import DATETIME_CONSTANT from '@src/constants/date-time-constants.js'
import generalMixins from '@src/mixins/general-mixins.js'
import Loader from '@components/BaseComponent/Loader.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'
import { mapState, mapActions } from 'vuex'
import { attendanceStatus } from '@src/constants/attandance/attandance-constant.js'
import { SCOPE_LEVELS } from '@src/constants/general-constants'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import { Form as ValidationObserver } from 'vee-validate'
import fileMixin from '@src/mixins/file-mixins'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    SingleUserDisplay,
    Loader,
    UiSingleSelect,
    InputFieldWrapper,
    NoRecordFound,
    UiStatus,
    ValidationObserver,
    TableWrapper,
    UiModalContainer,
    TBody,
    UIButton,
    TD,
    TH,
    THead,
    TableAction,
    TRBody,
    TRHead,
  },
  mixins: [generalMixins, fileMixin, scrollMixin],
  props: {
    attendanceList: {
      type: Array,
      default: () => [],
    },
    fields: {
      type: Array,
      default: () => [],
    },
    filtersData: {
      type: Object,
      default: () => {},
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      activeObj: {},
      attendanceStatusList: [attendanceStatus.ABSENT, attendanceStatus.PRESENT],
      actionList: [
        {
          name: 'Edit',
          roles: [
            TENANT_ROLES.SUPER_ADMIN,
            TENANT_ROLES.CAMPUS_ADMIN,
            TENANT_ROLES.SECTION_TEACHER,
          ],
        },
      ],
      PRESENT: 'present',
      showEditAttendanceModal: false,
      attendanceTranslation: 'attendanceTranslation',
      skipForFilterColumns: ['Student Name', 'Status', 'Period'],
      tableActionsPermissions: {
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
        roles: [TENANT_ROLES.SUPER_ADMIN, TENANT_ROLES.CAMPUS_ADMIN, TENANT_ROLES.SECTION_TEACHER],
      },
    }
  },
  computed: {
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
    }),
  },
  methods: {
    showColumnInDataTable,
    editAttendance(action, activeObj) {
      this.activeObj = objectDeepCopy(activeObj)
      this.toogleAttendanceModal()
    },
    toogleAttendanceModal() {
      this.showEditAttendanceModal = !this.showEditAttendanceModal
    },
    async updateAttendance() {
      const [res, err] = await this.updatePeriodAttendanceOfStudent(this.activeObj)
      if (res) {
        this.toogleAttendanceModal()
        this.$emit('update')
      }
    },
    redirectToDetailPage(period) {
      this.$router?.push({ name: 'students-period-attendance-details', params: { id: period.id } })
    },
    getScheduleLabel(schedule) {
      return `${schedule.subject.title} : ( ${formatDate(
        schedule.started_at,
        DATETIME_CONSTANT.time12H,
      )} - ${formatDate(schedule.ended_at, DATETIME_CONSTANT.time12H)} )`
    },
    ...mapActions('attendance', ['updatePeriodAttendanceOfStudent']),
  },
}
</script>

<style scoped lang="scss">
.attendance-list-view {
  &__header,
  &__body {
    > div {
      div {
        flex: 1;
        width: fit-content;
        min-width: 80px;
        padding: 10px;
        white-space: nowrap;
      }
      div:first-of-type {
        flex: 0;
      }
    }
  }
}
.student-border-top {
  border-top-width: 1px;
}
</style>
