<template>
  <div>
    <SubjectAttendanceFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <div class="main-content rounded-md mt-2">
      <div>
        <CalendarActionsWrapper
          :calendar-date="filterView === 'Week' ? dateTimeRange : currentDate"
          :filter-view="filterView"
          :filters="['Day']"
          @selectDate="setCalendarDate"
          @selectDateRange="setCalendarDateRange"
          @setFilter="setFilter"
          @applyFilter="applyDateFilter"
        ></CalendarActionsWrapper>
      </div>
      <div class="mt-2 items-center justify-center">
        <div>
          <UiCalendar
            v-if="filterView === 'Month'"
            :key="forceRenderCalendar"
            :calendar-date="currentDate"
          >
            <!-- change the way of passing Dynamic Slot Names -->
            <template
              v-for="event in attendance"
              :key="event.id"
              v-slot:[`day-${getDay(event.date)}`]
            >
              <div class="cursor-pointer">
                <div
                  v-if="event.isAction === false"
                  class="time-schedule flex justify-center items-center gap-1 text-xs status"
                  @click="SingleAttendanceDetail(event)"
                >
                  <p
                    class="dot dot_size h-2 w-2 rounded-full"
                    :style="{ backgroundColor: event.subject.color }"
                  ></p>
                  <span
                    class="text-white text-xs inline-block rounded-sm whitespace-no-wrap text_size presentDate"
                    :style="calculatePresentRate(event.presentRate)"
                    @click="singleAttendanceData(event)"
                  >
                    {{ `${getTimeOnly(event.startTime)}-${getTimeOnly(event.endTime)}` }}
                  </span>
                </div>
                <div v-if="event.isAction === true" class="time-schedule viewMore">
                  <p
                    class="font-roboto font-medium text-primary-grey-light viewMoreText cursor-pointer"
                    @click="viewMore(attendance, event.date)"
                  >
                    {{ $t(`schedule.View More`) + ' ...' }}
                  </p>
                </div>
                <div v-if="event.isAction === null"></div>
              </div>
            </template>
          </UiCalendar>
          <WeeklyAttendance
            v-else-if="filterView === 'Week'"
            :key="`weekly-${forceRenderCalendar}`"
            :theads="dayRange"
            :table-time="[
              timeRange[0] || dateTimeFormat.dayStartTime,
              timeRange[1] || dateTimeFormat.dayEndTime,
            ]"
            :events="events"
            :hide-overlap="false"
            :hide-actions="true"
            @viewEvent="viewEvent"
          ></WeeklyAttendance>
          <DayListView
            v-else-if="filterView === 'Day'"
            :key="forceRenderFilter"
            :fields="receiveList"
            :filters-data="filtersData"
            :table-headers="tableHeaders"
            :is-loading="isLoading"
            :attendance-list="attendance"
            @update="applyDateFilter"
          >
            <template v-slot:pagination>
              <Pagination
                v-if="attendanceCounts > GENERAL_CONSTANTS.RECORD_LIMIT"
                v-show="!isLoading"
                :key="`Pagination${forceRender}`"
                :record-limit="filteredRecordLimit"
                :max-range="paginationCounts(attendanceCounts, filteredRecordLimit)"
                @filterRecord="getStudentAttendances"
              />
            </template>
          </DayListView>
        </div>
      </div>
    </div>
    <MarkStudentAttendanceModal
      v-if="isMarkAttendanceModal"
      :modal="isMarkAttendanceModal"
      @toggle="toggleisMarkAttendanceModal"
    />

    <ViewMoreModal
      v-if="attendanceDetailModal"
      :modal="attendanceDetailModal"
      :attendances="detailsEventsArr ? detailsEventsArr : []"
      @toggle="attendanceDetailToggle"
    />
  </div>
</template>

<script>
import WeeklyAttendance from '@views/student/StudentCheckStatusModal/studentWeeklyAttendance.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import ViewMoreModal from '@views/student/StudentCheckStatusModal/StudentAttendanceMOdal.vue'
import CalendarActionsWrapper from '@components/Calendar/CalendarActionsWrapper.vue'
import UiCalendar from '@components/Calendar/index.vue'
import DayListView from '@views/attendance/period-attendance/student/StudentPeriodAttendanceDailyView.vue'
import SubjectAttendanceFilter from '@src/router/views/attendance/student-subject-attendance/subjectAttendanceFilter.vue'
import MarkStudentAttendanceModal from '@views/attendance/period-attendance/student/modals/MarkStudentPeriodAttendanceModal.vue'
import dateTimeFormat from '@src/constants/date-time-constants.js'
import { isEmpty } from 'lodash'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import timeMixin from '@src/mixins/timeMixin'
import { formatDateString, getWeekDayFromDate } from '@utils/moment.util'
import { getTimeRange } from '@src/utils/timeTableUtils.js'
import {
  getStudentAttendanceMonthly,
  getStudentAttendanceWeek,
  getSubjectEvent,
} from '@views/student/studentAttendanceUtils.js'
import { mapActions, mapState, mapGetters } from 'vuex'
import { attendanceColor } from '@src/router/views/attendance/attendanceUtils.js'
import {
  attendanceColorCodes,
  ATTENDANCE_GOOD,
  ATTENDANCE_OK,
} from '@src/constants/attandance/attandance-constant.js'
import { objectDeepCopy } from '@/src/utils/generalUtil'
import fileMixin from '@src/mixins/file-mixins'
import Pagination from '@components/BaseComponent/Pagination.vue'
import generalMixins from '@src/mixins/general-mixins.js'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import periodAttendanceData from '@src/router/views/attendance/period-attendance/student/periodAttendanceData.json'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import { getStartTimeOfTheDay, getEndTimeOfTheDay } from '@utils/moment.util'
import { TENANT_ROLES } from '@src/constants/user-roles-constants.js'

export default {
  name: 'StudentAttendance',
  components: {
    CalendarActionsWrapper,
    UiCalendar,
    WeeklyAttendance,
    SubjectAttendanceFilter,
    DayListView,
    ViewMoreModal,
    MarkStudentAttendanceModal,
    Pagination,
  },
  mixins: [generalMixins, fileMixin, timeMixin],
  page: {
    title: 'Student Attendance',
    meta: [{ name: 'description', content: 'Student Attendance' }],
  },
  data() {
    return {
      GENERAL_CONSTANTS,
      dateTimeFormat,
      filtersData: {},
      defaultSelectedColumns: periodAttendanceData.defaultSelectedColumns,
      tableHeaders: periodAttendanceData.tableHeaders,
      title: 'title',
      filterView: 'Day',
      filterCourse: null,
      filterSchedule: null,
      date: null,
      time: null,
      isLoading: false,
      selectedField: [],
      apiLoading: false,
      noRecord: isEmpty(this.attendance),
      scheduleList: [],
      classes: [],
      receiveList: [],
      attendanceTranslation: 'attendanceTranslation',
      status: ['present', 'absent', 'late'],
      subject_id: '',
      studentList: [],
      schedule_id: '',
      currentDate: new Date(),
      filterDay: null,
      filterStudent: null,
      forceRenderCalendar: 1,
      forceRenderFilter: 1,
      forceRender: 1,
      dateTimeRange: null,
      events: [],
      dayRange: ['monday', 'friday'],
      timeRange: [null, null],
      attendance: [],
      tempDate: {},
      attendanceDetailModal: false,
      detailsEventsArr: [],
      respDataObjDump: [],
      isMarkAttendanceModal: false,
      filteredRecordLimit: 10,
      attendanceCounts: 0,
    }
  },
  computed: {
    ...mapState({
      activeRole: (state) => state.layout.activeRole,
      showModal: (state) => state.layout.showModal,
      teacherAttendanceOutModal: (state) => state.layout.teacherAttendanceOutModal,
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentUser: (state) => state.auth.currentUser?.id,
    }),
    ...mapGetters('layout', ['campusTimeZone']),
  },
  watch: {
    currentSectionScope: {
      deep: true,
      handler() {
        if (this.currentSectionScope) {
          this.getStudentAttendances()
          this.forceRender++
          this.getSubjectList()
        }
      },
    },
    showModal: {
      handler(value) {
        if (value) this.toggleisMarkAttendanceModal()
      },
    },
  },

  created() {
    this.setRightBarData()
  },
  mounted() {
    this.getStudentAttendances()
    this.getSubjectList()
  },
  methods: {
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.getStudentAttendances()
      this.forceRender++
    },
    toggleisMarkAttendanceModal(payload) {
      this.isMarkAttendanceModal = !this.isMarkAttendanceModal
      if (!this.isMarkAttendanceModal) this.$store.dispatch('layout/setShowModal', false)
      if (payload) {
        this.getStudentAttendances()
        this.forceRender++
      }
    },
    selectedFields(val) {
      this.receiveList = val
      this.forceRenderFilter++
    },
    calculatePresentRate(rate) {
      if (rate > ATTENDANCE_GOOD) return { backgroundColor: attendanceColorCodes.PRESENT }
      else if (rate >= ATTENDANCE_OK && rate <= ATTENDANCE_GOOD)
        return { backgroundColor: attendanceColorCodes.DELAY }
      else return { backgroundColor: attendanceColorCodes.ABSENT }
    },
    /**
     * Get Subject List
     * @description Function Description :
     * - getting the class list from the state
     */
    getSubjectList() {
      this.classSubjects({
        section_id: this.currentSectionScope,
      }).then((resp) => {
        this.subjects = resp.data?.records || []
      })
    },
    SingleAttendanceDetail(event) {
      this.detailsEventsArr = []
      this.detailsEventsArr.push(event)
      this.attendanceDetailModal = true
    },

    setRightBarData(stats, title) {
      const rightBarContent = {
        header: {
          title: 'Attendance',
          buttons: [
            {
              title: 'Mark Attendance',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowModal', payload: true },
              permissions: { roles: ['super_admin', 'campus_admin', 'section_teacher'] },
            },
          ],
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'TOTAL_STUDENTS',
                value: stats?.total_students || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
              {
                text: 'TOTAL_PERIOD',
                value: stats?.total_periods || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
              {
                text: 'TOTAL_PERIOD_ATTENDANCES',
                value: stats?.period_attendances_count || GENERAL_CONSTANTS.EMPTY_RECORD,
              },
              {
                text: 'PRESENT_STUDENT',
                value: stats?.present_count || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
              {
                text: 'ABSENT_STUDENT',
                value: stats?.absent_count || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [
                  TENANT_ROLES.SUPER_ADMIN,
                  TENANT_ROLES.SECTION_TEACHER,
                  TENANT_ROLES.CAMPUS_ADMIN,
                ],
              },
              {
                text: 'TOTAL_PRESENTS',
                value: stats?.present_count || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN],
              },
              {
                text: 'TOTAL_ABSENTS',
                value: stats?.absent_count || GENERAL_CONSTANTS.EMPTY_RECORD,
                roles: [TENANT_ROLES.SECTION_STUDENT, TENANT_ROLES.GUARDIAN],
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    applyDateFilter() {
      this.getStudentAttendances()
      this.forceRender++
    },

    getStudentAttendances(range) {
      if (!this.currentSectionScope) return
      let paginationRange = paginationRangeHandler(range)
      let filterQueryParams = {
        ...paginationRange,
      }
      filterQueryParams = this.filtersData
        ? { ...filterQueryParams, ...{ $where: this.filtersData?.$where } }
        : filterQueryParams

      if (this.activeRole === 'section_teacher') filterQueryParams.teacher_id = this.currentUser

      if (this.filterView === 'Month') {
        filterQueryParams.month_year = formatDateString(this.currentDate)
      } else if (this.filterView === 'Week') {
        filterQueryParams.from_date = formatDateString(this.dateTimeRange.startDate)
        filterQueryParams.to_date = formatDateString(this.dateTimeRange.endDate)
      } else if (this.filterView === 'Day') {
        filterQueryParams.$where = {
          ...filterQueryParams.$where,
          period_at: {
            [FILTER_KEYS.GREATER_THAN_EQUAL]: `${getStartTimeOfTheDay(this.currentDate)}${
              this.campusTimeZone
            }`,
            [FILTER_KEYS.LESS_THAN_EQUAL]: `${getEndTimeOfTheDay(this.currentDate)}${
              this.campusTimeZone
            }`,
          },
        }
      }

      this.isLoading = true
      return this.getStudentPeriodAttendance(filterQueryParams)
        .then((resp) => {
          this.noRecord = isEmpty(resp.records)
          this.setRightBarData(resp.meta, 'title')
          this.attendanceCounts = resp?.meta?.total_records
          if (this.filterView === 'Month') {
            this.attendance = getStudentAttendanceMonthly(resp)
          } else if (this.filterView === 'Week') {
            this.respDataObjDump = resp.records
            const attendances = getStudentAttendanceWeek(resp)
            const timeRange = getTimeRange(attendances)
            this.timeRange.splice(0, 1, timeRange[0])
            this.timeRange.splice(1, 1, timeRange[1])

            this.events = attendances
          } else if (this.filterView === 'Day') {
            this.attendance = resp?.records
            this.attendance.forEach((attendance) => {
              fullName(attendance.student)
            })
          }

          this.forceRenderCalendar++
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    setFilter(filterView) {
      this.attendance = []
      this.filterView = filterView
      if (filterView === 'Week') {
        this.initializeFilterForWeek(this.currentDate)

        this.getStudentAttendances()
        this.forceRenderCalendar++
      } else {
        this.getStudentAttendances()
      }
    },

    initializeFilterForWeek(startingDate, endingDate = null) {
      const startDate = new Date(startingDate)
      const endDate = new Date(startingDate)

      // Setting this to only get range for 7 days apart
      endDate.setDate(endDate.getDate() + 6)

      const startDayOfWeek = getWeekDayFromDate(startDate)
      const endDayOfWeek = getWeekDayFromDate(endDate)

      this.dateTimeRange = {
        startDate: startDate,
        endDate: endDate,
      }

      this.tempDate.dayRange = this.dayRange

      this.dayRange.splice(0, 1, startDayOfWeek)
      this.dayRange.splice(1, 1, endDayOfWeek)
    },
    /**
     * Get Time Only
     * @param { String }  - time
     * @returns {String} - time
     * @description Function Description :
     * - getting only start and end time of the marked schedule to display in the table of
     */

    getTimeOnly(time) {
      return time.split(' ')[0]
    },

    /**
     * Get Day
     * @param { String }  - date
     * @returns {String} - date
     * @description Function Description :
     * - geting only date of the marked schedule to display in the table of
     */
    getDay(date) {
      return new Date(date).getDate()
    },

    /**
     * Set Calendar Date
     * @param { String }  - dateTime
     * @returns {Void}
     * @description Function Description :
     * - geting the date of the previous day by clicking on the previous button in param
     * - assigning that date to the current date
     */
    setCalendarDate(dateTime) {
      this.tempDate.currentDate = this.currentDate
      this.currentDate = dateTime
    },

    /**
     * Set CalendarDate
     * @param { String }  - selectedDateTimeRange
     * @returns {Void}
     * @description Function Description :
     * - geting the date of the selected start day and end day for the week range
     * - assigning that date to the current date
     */

    setCalendarDateRange(selectedDateTimeRange) {
      this.tempDate.dateTimeRange = this.dateTimeRange
      this.dateTimeRange = selectedDateTimeRange

      this.initializeFilterForWeek(this.dateTimeRange.startDate, this.dateTimeRange.endDate)

      this.forceRenderCalendar++
    },

    /**
     * Apply Filter
     * @description Function Description :
     * @returns {Void}
     * - if monthly filter, setting student attendance data, formatting the date and setting the rightbar meta data
     * - if weekly filter, filtering the data by start and end date range , also filtering the attendance by the specific subject in that range
     */

    applyFilter() {
      if (this.filterView === 'Month') {
        const data = {}
        data.subjec_id = this.filterCourse
        data.month_year = formatDateString(this.currentDate)
        this.getStudentPeriodAttendance(data).then((response) => {
          this.attendance = getStudentAttendanceMonthly(response)
          this.setRightBarData(response.over_all_meta)
        })
      } else if (this.filterView === 'Week') {
        const data = {}
        data.from_date = formatDateString(this.dateTimeRange.startDate)
        data.to_date = formatDateString(this.dateTimeRange.endDate)
        data.subject_id = this.filterCourse
        this.getStudentPeriodAttendance(data).then((response) => {
          let attendances = null
          const timeRange = null
          this.respDataObjDump = response.records
          attendances = getStudentAttendanceWeek(response)
          this.setRightBarData(response.over_all_meta)
          this.timeRange.splice(0, 1, timeRange[0])
          this.timeRange.splice(1, 1, timeRange[1])

          this.events = attendances
        })
      }
    },

    attendanceDetailToggle() {
      this.attendanceDetailModal = !this.attendanceDetailModal
    },

    /**
     * Single AttendanceData
     * @param { Object }  - event
     * @returns {Void}
     * @description Function Description :
     * - getting the object for a single attendance event as param and storing in detailsEventsArr
     */
    singleAttendanceData(event) {
      this.detailsEventsArr = []
      this.detailsEventsArr = event
    },

    /**
     * View More
     * @param { Object , String}  - allAttendance , date
     * @returns {Void}
     * @description Function Description :
     * - getting the all attendance events for a specific date and storing in detailsEventsArr
     */
    viewMore(allAttendance, date) {
      this.attendanceDetailToggle()
      this.detailsEventsArr = []
      allAttendance.map((attendance) => {
        if (attendance.date === date) {
          this.detailsEventsArr.push(attendance)
        }
      })
    },

    viewEvent(eventId) {
      let detailsEventsArr = []
      const event = this.events.find((event) => event.id === eventId)
      if (event) {
        detailsEventsArr.push(event)

        if (event?.isOverlapped) {
          const { overlappedIds } = event || {}
          let allOverlappedEvents = null
          const attendances = []
          // format Data
          Object.keys(this.respDataObjDump).forEach((date) => {
            this.respDataObjDump[date].forEach((record) => {
              record.subject_attendance.forEach((idx) => {
                const color = attendanceColor(idx.status)
                const count = {
                  present: record.meta.presents,
                  absent: record.meta.absents,
                  delay: record.meta.delays,
                }
                const event = getSubjectEvent(idx.schedule, color, count)
                attendances.push(event)
              })
            })
          })
          allOverlappedEvents = overlappedIds.map((id) => {
            return attendances.find((obj) => obj.id === id)
          })

          detailsEventsArr = [...detailsEventsArr, ...allOverlappedEvents]
        }
        this.detailsEventsArr = detailsEventsArr
        this.attendanceDetailModal = true
      }
    },
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('subjects', ['classSubjects']),
    ...mapActions('student', ['getStudentPeriodAttendance']),
  },
}
</script>

<style lang="scss">
.status {
  margin-bottom: 2px;
}
.viewMore {
  font-size: 11px;
}
</style>
