<template>
  <ValidationObserver @submit="markAttendance">
    <UiModalContainer
      footer-classes="flex justify-end"
      :modal-show="modal"
      get-student-attendances
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span v-i18n="attendanceTranslation">Mark Attendance</span>
      </template>
      <template v-slot>
        <div>
          <InputFieldWrapper>
            <UiDatePicker
              v-model="attendanceData.date"
              class="flex-1"
              title="Date"
              @change="getPeriodsList(attendanceData.date)"
            />
            <UiSingleSelect
              v-model="attendanceData.schedule"
              title="SCHED"
              :options="periodsList"
              label="schedule_name"
              class="flex-1"
              rules="required"
              @change="getStudentsBasesOnSchedule(attendanceData.schedule.id)"
            />
          </InputFieldWrapper>
        </div>
        <!-- Student List -->
        <div v-if="attendanceData.schedule">
          <div v-if="isLoading" class="mt-20 mb-48">
            <Loader :content="true" />
          </div>
          <div v-else-if="!isLoading && isEmpty(currentList)" class="mt-20 mb-48">
            <NoRecordFound />
          </div>
          <div v-else>
            <div
              class="font-medium font-roboto text-xl text-label-text mb-5 pt-5 border-t border-primary-grey"
            >
              Students
            </div>
            <TableWrapper id="mark-attendance-table">
              <THead>
                <TRHead>
                  <TH>
                    <span v-i18n="attendanceTranslation">Student Name</span>
                  </TH>
                  <TH>
                    <span class="flex gap-2.5 items-center">
                      <div>
                        <UiCheckbox
                          :no-error="true"
                          :model-value="toggleAllPresent"
                          @change="markAllPresent(!toggleAllPresent)"
                        />
                      </div>
                      <span v-i18n="attendanceTranslation" class="flex">SAM_PT</span>
                    </span>
                  </TH>
                  <TH>
                    <span class="flex gap-2.5 items-center">
                      <div>
                        <UiCheckbox
                          :no-error="true"
                          :model-value="toggleAllAbsent"
                          @change="setAllAbsent(!toggleAllAbsent)"
                        />
                      </div>
                      <span v-i18n="attendanceTranslation">SAM_AT</span>
                    </span>
                  </TH>
                  <TH>
                    <span v-i18n="attendanceTranslation">COMMENT</span>
                  </TH>
                </TRHead>
              </THead>
              <TBody>
                <TRBody v-for="(student, index) in currentList" :key="index">
                  <TD>
                    <SingleUserDisplay :user="student" label="full_name" :image="true" />
                  </TD>
                  <TD :key="reRender">
                    <UiCheckbox
                      :key="reRender"
                      type="checkbox"
                      :name="`status-${index}`"
                      :no-error="true"
                      :model-value="student.status === ATTENDANCE.PRESENT"
                      @change="updateStatus('present', index, $event)"
                    />
                  </TD>
                  <TD :key="reRender">
                    <UiCheckbox
                      type="checkbox"
                      :name="`status-${index}`"
                      :no-error="true"
                      :model-value="student.status === ATTENDANCE.ABSENT"
                      @change="updateStatus('absent', index, $event)"
                    />
                  </TD>
                  <TD>
                    <UiInputBox
                      v-model="student.comment"
                      type="text"
                      :name="`Comment-${index} ${student.id}`"
                      title="Comment"
                      label="Comment"
                      :hide-title="true"
                      placeholder="Comment"
                      class="flex-1 w-full mt-3"
                    />
                  </TD>
                </TRBody>
              </TBody>
            </TableWrapper>
          </div>
          <Pagination
            v-if="studentList.length > RECORD_LIMIT"
            :key="initiatePagination"
            :record-limit="RECORD_LIMIT"
            :max-range="paginationCounts(studentList.length, RECORD_LIMIT)"
            @filterRecord="filterRecord"
          />
        </div>
      </template>
      <template v-slot:footer>
        <div class="w-full -mt-4">
          <div
            v-if="studentList.length > 0"
            v-i18n="Footer"
            class="xl:text-lg sm:text-base text-sm text-primary-purple-600"
          >
            ATTENDANCE_NOTE
          </div>
          <div class="flex gap-3 justify-end mt-4">
            <UIButton @click="handleClick('close')">Cancel</UIButton>
            <UIButton
              button="primary"
              :disabled="disableButton"
              :class="disableButton ? 'disabled-btn' : ''"
            >
              <template v-if="isLoading"><Loader /></template>
              <template v-else>
                <span>Mark</span>
              </template>
            </UIButton>
          </div>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script>
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import { Form as ValidationObserver } from 'vee-validate'
import Loader from '@components/BaseComponent/Loader.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import DATETIME_CONSTANT from '@src/constants/date-time-constants.js'
import UiCheckbox from '@components/UiElements/UiCheckbox.vue'
import GENERAL_CONSTANT, { ATTENDANCE } from '@src/constants/general-constants'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixins from '@src/mixins/general-mixins.js'
import isEmpty from 'lodash/isEmpty'
import UiInputBox from '@components/UiElements/UiInputBox.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import { formatDate, formatDateFromLocalToUTC } from '@utils/moment.util'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import { fullName } from '@src/utils/settings/tenant-user.util.js'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { getStartTimeOfTheDay, getEndTimeOfTheDay } from '@utils/moment.util'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import UIButton from '@src/components/UiElements/UIButton.vue'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  components: {
    UiDatePicker,
    UiSingleSelect,
    ValidationObserver,
    Loader,
    UiModalContainer,
    UiCheckbox,
    Pagination,
    UiInputBox,
    SingleUserDisplay,
    NoRecordFound,
    UIButton,
    InputFieldWrapper,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixins],
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['toggle'],
  data() {
    return {
      ATTENDANCE,
      Footer: 'Footer',
      attendanceTranslation: 'attendanceTranslation',
      selectedDate: new Date(),
      currentDate: new Date(),
      attendanceData: {
        date: formatDateFromLocalToUTC(new Date()),
        schedule: '',
      },
      periodsList: [],
      students: [],
      RECORD_LIMIT: GENERAL_CONSTANT.RECORD_LIMIT,
      isLoading: false,
      toggleAllPresent: false,
      toggleAllAbsent: false,
      arrayStart: 0,
      arrayEnd: GENERAL_CONSTANT.RECORD_LIMIT,
      disableButton: true,
      studentList: [],
      reRender: 1,
      initiatePagination: 0,
    }
  },
  computed: {
    ...mapState({
      activeRole: (state) => state?.layout?.activeRole,
      selectedClass: (state) => state?.layout?.currentSectionScope?.id,
      currentUser: (state) => state?.auth?.currentUser?.id,
      studentListScheduleBase: (state) => state.student.studentListScheduleBase,
    }),
    currentList() {
      return this.studentList.slice(this.arrayStart, this.arrayEnd)
    },
    ...mapGetters('layout', ['campusTimeZone']),
  },
  watch: {
    studentListScheduleBase: {
      handler(value) {
        this.studentList = objectDeepCopy(value)
        this.studentList.forEach((student) => {
          fullName(student)
        })
      },
    },
    currentList: {
      handler(students) {
        let allPresent = students.every((student) => student.status === 'present')
        let allAbsent = students.every((student) => student.status === 'absent')
        if (allPresent) {
          this.toggleAllPresent = true
          this.toggleAllAbsent = false
        } else if (allAbsent) {
          this.toggleAllPresent = false
          this.toggleAllAbsent = true
        } else {
          this.toggleAllPresent = undefined
          this.toggleAllAbsent = undefined
        }
      },
    },
  },
  mounted() {
    this.getPeriodsList(this.attendanceData.date)
  },
  methods: {
    isEmpty,
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.$emit('toggle')
          break
      }
    },

    getStudentsBasesOnSchedule(id) {
      let payload = {
        period_id: id,
      }
      this.isLoading = true
      this.initiatePagination++
      this.getScheduledStudents(payload).then(() => {
        this.isLoading = false
        this.filterRecord()
      })
    },
    getPeriodsList(date) {
      let startTime = {
        [FILTER_KEYS.GREATER_THAN_EQUAL]: `${getStartTimeOfTheDay(date)}${this.campusTimeZone}`,
        [FILTER_KEYS.LESS_THAN_EQUAL]: `${getEndTimeOfTheDay(date)}${this.campusTimeZone}`,
      }

      let payload = {
        $where: {
          started_at: startTime,
        },
      }
      this.attendanceData.schedule = ''
      this.filterPeriod(payload).then((response) => {
        this.periodsList = response?.data?.records || []
        this.periodsList.forEach((record) => {
          const startDate = formatDate(record.started_at, DATETIME_CONSTANT.time12H)
          const endDate = formatDate(record.ended_at, DATETIME_CONSTANT.time12H)
          record.schedule_name = `${record.subject.title}: ${startDate} - ${endDate}`
        })
      })
    },
    markAllPresent(status) {
      if (status) {
        this.currentList.map((student) => {
          student.status = 'present'
        })
      } else {
        this.currentList.map((student) => {
          student.status = undefined
        })
      }
      this.allCheckForFilterRecord()
    },
    setAllAbsent(status) {
      if (status) {
        this.toggleAllAbsent = true
        this.toggleAllPresent = false
        this.currentList.map((student) => {
          student.status = 'absent'
        })
      } else {
        this.toggleAllAbsent = false
        this.currentList.map((student) => {
          student.status = undefined
        })
      }
      this.allCheckForFilterRecord()
    },
    async updateStatus(status, index, e) {
      let val = status
      val = e.status ? status : undefined

      this.currentList[index].status = val
      this.reRender++
      this.allCheckForFilterRecord()
    },
    filterRecord(
      range = { skip: GENERAL_CONSTANT.RECORD_SKIP, limit: GENERAL_CONSTANT.RECORD_LIMIT },
    ) {
      this.arrayStart = range.skip
      this.arrayEnd = range.skip + range.limit
      let totalRequestedCount = range.skip + range.limit
      if (this.studentList.length && totalRequestedCount >= this.studentList.length) {
        this.disableButton = false
      }
    },
    allCheckForFilterRecord() {
      if (Array.isArray(this.currentList)) {
        /* CHECKING IF ALL ARE NOT UNDEFINED */
        /* SETTING INITIAL */
        this.toggleAllPresent = false
        this.toggleAllAbsent = false
        var checkForUndifined = this.currentList.some((student) => {
          return student.status === undefined
        })
        if (!checkForUndifined) {
          let allPresentBool = this.currentList.every((student) => {
            return student.status === 'present'
          })
          this.toggleAllPresent = allPresentBool
          let allAbsentBool = this.currentList.every((student) => {
            return student.status === 'absent'
          })
          this.toggleAllAbsent = allAbsentBool
        }
      }
    },

    async markAttendance() {
      this.disableButton = true
      let studentList = this.studentList.map((student) => {
        return {
          student_user_id: student.id,
          status: student?.status || 'absent',
          comment: student?.comment || '',
        }
      })
      let data = {
        period_id: this.attendanceData?.schedule?.id,
        students: studentList,
      }
      try {
        let [res, error] = await this.markPeriodAttendance(data)

        if (res) {
          this.$emit('toggle', true)
        }
      } finally {
        this.disableButton = false
      }
    },
    ...mapActions('period', ['filterPeriod']),
    ...mapActions('layout', ['setTeacherAttendanceModal']),
    ...mapActions('student', ['markAttendanceForStudent', 'getScheduledStudents']),
    ...mapActions('attendance', ['markPeriodAttendance']),
  },
}
</script>

<style lang="scss" scoped>
.disabled-btn {
  background-color: var(--primary-grey);
  border: none;
}

@media (min-width: 500px) and(max-width: 639px) {
  .custom-max-w {
    width: 360px;
  }
}
@media (max-width: 499px) {
  .custom-max-w {
    width: 296px;
  }
}
</style>
